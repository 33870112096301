import { Component, HostBinding, HostListener, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-picture-tile",
  templateUrl: "./picture-tile.component.html",
  styleUrls: ["./picture-tile.component.scss"],
})
export class PictureTileComponent implements OnInit {
  @Input() background;
  @Input() fullscreen;
  @Input() name;

  constructor() {}

  @HostListener("click") onClick() {
    this.fullscreen = !this.fullscreen;
  }

  ngOnInit(): void {}
}
