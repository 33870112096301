import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { AppComponent } from "./app.component";
import { WebModule } from "./web/web.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AdminPanelModule } from "./admin-panel/admin-panel.module";

// export function apiConfigFactory(): Configuration {
//   const params: ConfigurationParameters = {
//     apiKeys: { Authorization: undefined },
//     basePath: window.location.protocol + "//" + window.location.host,
//   };
//   return new Configuration(params);
// }
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    BrowserAnimationsModule,
    HttpClientModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    RouterModule.forRoot(
      [
        { path: "web", loadChildren: () => WebModule },
        { path: "admin-panel", loadChildren: () => AdminPanelModule },
        { path: "**", redirectTo: "web" },
      ],
      { scrollPositionRestoration: "enabled" }
    ),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
