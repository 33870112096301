import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ArticleDto, ArticlesClient } from "api/api";
import { take } from "rxjs/operators";

@Component({
  selector: "app-blog-details-page",
  templateUrl: "./blog-details-page.component.html",
  styleUrls: ["./blog-details-page.component.scss"],
})
export class BlogDetailsPageComponent implements OnInit {
  article: ArticleDto;

  constructor(private route: ActivatedRoute, articlesClient: ArticlesClient) {
    this.route.params.pipe(take(1)).subscribe((params) => {
      articlesClient.getArticles().subscribe((articles) => (this.article = articles.find((a) => a.id == params["id"]))); //TODO add get(id) method
    });
  }

  ngOnInit(): void {}
}
