import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectionListChange } from "@angular/material/list";
import { GroupDto, GroupsClient, PermissionDto } from "api/api";
import { AlertService, MessageType } from "src/app/web/services/alert.service";
import { UserWizardComponent } from "../../users/user-wizard/user-wizard.component";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";

export class PermissionNode {
  constructor() {}
  source: PermissionDto;
  children: Array<PermissionNode>;
  name: string;
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
@Component({
  selector: "app-group-wizard",
  templateUrl: "./group-wizard.component.html",
  styleUrls: ["./group-wizard.component.scss"],
})
export class GroupWizardComponent implements OnInit {
  constructor(
    private groupService: GroupsClient,
    private alertService: AlertService,
    public dialog: MatDialogRef<GroupWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  private _transformer = (node: PermissionNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      source: node.source,
      level: level,
    };
  };
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  group: GroupDto;

  ngOnInit() {
    if (this.data.mode == "edit" || this.data.mode === "view") {
      this.groupService.get2(this.data.group.id).subscribe((result) => {
        this.group = new GroupDto(result);
        const dataSource: PermissionNode[] = [];
        this.group.permissions.forEach((element) => {
          let el: PermissionNode;
          if ((el = dataSource.find((n) => n.name === element.friendlyControllerName)) === undefined) {
            dataSource.push({
              source: null,
              name: element.friendlyControllerName,
              children: [{ source: element, name: element.friendlyMethodName, children: [] }],
            });
          } else {
            el.children.push({ source: element, name: element.friendlyMethodName, children: [] });
          }
        });

        this.dataSource.data = dataSource;
      });
    } else {
      this.groupService.permissionList().subscribe((result) => {
        this.group = new GroupDto();
        this.group.permissions = result;
        const dataSource: PermissionNode[] = [];
        this.group.permissions.forEach((element) => {
          let el: PermissionNode;
          if ((el = dataSource.find((n) => n.name === element.friendlyControllerName)) === undefined) {
            dataSource.push({
              source: null,
              name: element.friendlyControllerName,
              children: [{ source: element, name: element.friendlyMethodName, children: [] }],
            });
          } else {
            el.children.push({ source: element, name: element.friendlyMethodName, children: [] });
          }
        });

        this.dataSource.data = dataSource;
      });
    }
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  onSubmit(form): void {
    switch (this.data.mode) {
      case "create":
        this.groupService.add(this.group).subscribe((result: any) => {
          this.alertService.showMessage("Pomyślnie dodano grupę", MessageType.Info);
          this.dialog.close(true);
        });
        break;
      case "edit":
        this.groupService.update2(this.data.group.id, this.group).subscribe((result: any) => {
          this.alertService.showMessage("Pomyślnie zaktualizowano grupę", MessageType.Info);
          this.dialog.close(true);
        });
        break;
    }
  }

  selectAllPermissions() {
    const t = this.group.permissions.map((p) => (p.granted = true));
  }

  selectionChange($event: MatSelectionListChange) {
    if ($event.option.value == null) {
      return;
    }
    const t = this.group.permissions.find(
      (o) => o.methodName === $event.option.value.source.methodName && o.controllerName === $event.option.value.source.controllerName
    );
    t.granted = $event.option.selected;
  }
}
