import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-contact-tile-title",
  templateUrl: "./contact-tile-title.component.html",
  styleUrls: ["./contact-tile-title.component.scss"],
})
export class ContactTileTitleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
