import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomePageComponent } from "./components/home-page/home-page.component";
import { RouterModule } from "@angular/router";
import { GalleryPageComponent } from "./components/gallery-page/gallery-page.component";
import { WebComponent } from "./web.component";
import { TitleBarComponent } from "./components/title-bar/title-bar.component";
import { SubTitleBarComponent } from "./components/sub-title-bar/sub-title-bar.component";
import { RoundImageComponent } from "./components/round-image/round-image.component";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckbox, MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { ProductsPageComponent } from "./components/products-page/products-page.component";
import { PaymentDialogComponent } from "./components/products-page/payment-dialog/payment-dialog.component";
import { ContactPageComponent } from "./components/contact-page/contact-page.component";
import { BumperComponent } from "./components/bumper/bumper.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ContactTileComponent } from "./components/contact-page/contact-tile/contact-tile.component";
import { ContactTileTitleComponent } from "./components/contact-page/contact-tile/contact-tile-title/contact-tile-title.component";
import { ButtonComponent } from "./components/button/button.component";
import { VideoTileComponent } from "./components/products-page/video-tile/video-tile.component";
import { DialogComponent } from "./components/dialog/dialog.component";
import { FocusOnShowDirective, LoginPageComponent } from "./components/login-page/login-page.component";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { RegisterationPageComponent } from "./components/registeration-page/registeration-page.component";
import { CartPageComponent } from "./components/cart-page/cart-page.component";
import { PictureTileComponent } from "./components/gallery-page/picture-tile/picture-tile.component";
import { ProductDetailsComponent } from "./components/products-page/product-details/product-details.component";
import { AboutUsPageComponent } from "./components/about-us-page/about-us-page.component";
import { animate } from "@angular/animations";
import { FooterComponent } from "./components/footer/footer.component";
import { AboutPlatformPageComponent } from "./components/about-platform-page/about-platform-page.component";
import { BlogPageComponent } from "./components/blog-page/blog-page.component";
import { BlogDetailsPageComponent } from "./components/blog-details-page/blog-details-page.component";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { PolicyComponent } from "./components/policy/policy.component";
import { ProductDetailsPageComponent } from "./components/products-page/product-details-page/product-details-page.component";
import { PolicyPageComponent } from "./components/policy-page/policy-page.component";
import { PrivacyPolicyPageComponent } from "./components/privacy-policy-page/privacy-policy-page.component";

@NgModule({
  declarations: [
    HomePageComponent,
    GalleryPageComponent,
    WebComponent,
    TitleBarComponent,
    SubTitleBarComponent,
    RoundImageComponent,
    NavigationComponent,
    ProductsPageComponent,
    PaymentDialogComponent,
    ContactPageComponent,
    BumperComponent,
    ContactTileComponent,
    ContactTileTitleComponent,
    ButtonComponent,
    VideoTileComponent,
    DialogComponent,
    LoginPageComponent,
    RegisterationPageComponent,
    CartPageComponent,
    PictureTileComponent,
    ProductDetailsComponent,
    AboutUsPageComponent,
    FooterComponent,
    AboutPlatformPageComponent,
    BlogPageComponent,
    BlogDetailsPageComponent,
    FocusOnShowDirective,
    PaginatorComponent,
    PolicyComponent,
    ProductDetailsPageComponent,
    PolicyPageComponent,
    PrivacyPolicyPageComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatSnackBarModule,
    MatCheckboxModule,
    FormsModule,
    FlexLayoutModule,
    RouterModule.forChild([
      {
        path: "",
        component: WebComponent,
        children: [
          { path: "home", component: HomePageComponent, data: { animation: "HomePage" } },
          { path: "gallery", component: GalleryPageComponent, data: { animation: "GalleryPage" } },
          {
            path: "products",
            component: ProductsPageComponent,
            data: { animation: "ProductsPage" },
          },
          {
            path: "product-details/:id",
            component: ProductDetailsPageComponent,
            data: { animation: "ProductDetailsPage" },
          },
          { path: "contact", component: ContactPageComponent, data: { animation: "ContactPage" } },
          { path: "login", component: LoginPageComponent, data: { animation: "LoginPage" } },
          { path: "register", component: RegisterationPageComponent, data: { animation: "RegisterPage" } },
          { path: "about-us", component: AboutUsPageComponent, data: { animation: "AboutUsPage" } },
          { path: "about-platform", component: AboutPlatformPageComponent, data: { animation: "AboutPlatformPage" } },
          { path: "cart", component: CartPageComponent, data: { animation: "CartPage" } },
          { path: "blog", component: BlogPageComponent, data: { animation: "BlogPage" } },
          { path: "blog-details/:id", component: BlogDetailsPageComponent, data: { animation: "BlogDetailsPage" } },
          { path: "policy", component: PolicyPageComponent, data: { animation: "PolicyPage" } },
          { path: "privacy-policy", component: PrivacyPolicyPageComponent, data: { animation: "PrivacyPolicyPage" } },

          { path: "**", redirectTo: "home" },
        ],
      },
    ]),
  ],
  bootstrap: [HomePageComponent],
})
export class WebModule {}
