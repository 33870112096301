import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ChapterDto, CourseDto, Video } from "api/api";
import { AlertService, MessageType } from "src/app/web/services/alert.service";
import { CartService } from "src/app/web/services/cart.service";

export class ChapterItem extends ChapterDto {
  clicked = false;
}
export class CourseItem extends CourseDto {
  clicked = false;
}

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit {
  @Input() course: CourseItem;

  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() addToCart: EventEmitter<void> = new EventEmitter();

  @ViewChild("videoPlayer") videoPlayer: ElementRef<HTMLVideoElement>;

  activeChapter: ChapterDto;
  activeVideo: Video;
  cart: ChapterItem[] = [];

  currentVideoUrl = "";

  constructor(public cartService: CartService, private alertService: AlertService) {}

  ngOnInit(): void {
    this.setActiveChapter(undefined);
  }

  public closeDialog() {
    this.close.next();
  }

  isAddedToCart(chapter: ChapterItem) {
    return this.cart.indexOf(chapter) !== -1;
  }

  setActiveVideo(video: Video) {
    this.activeVideo = video;
    this.currentVideoUrl = "/api/Course/video/" + this.activeChapter?.id + "/" + this.activeVideo.fileName;
    this.videoPlayer?.nativeElement?.load();
  }

  setActiveChapter(chapter: ChapterDto) {
    if (chapter == undefined) {
      this.activeVideo = this.course.chapters[0].videos[0];
      this.activeChapter = undefined;

      this.currentVideoUrl = "/api/Course/video/" + this.course.chapters[0].id + "/" + this.activeVideo.fileName;
    } else {
      this.activeChapter = chapter;
      this.activeVideo = chapter.videos[0];
      this.currentVideoUrl = "/api/Course/video/" + this.activeChapter?.id + "/" + this.activeVideo.fileName;
    }
    this.videoPlayer?.nativeElement?.load();
  }

  addToShoppingCart(course: CourseItem, chapter: ChapterItem) {
    this.cartService.addToShopingCart(course, chapter);
    if (chapter != undefined) {
      if (!chapter.clicked) this.alertService.showMessage("Pomyślnie dodano do koszyka", MessageType.Info);
      chapter.clicked = true;
    } else {
      if (!course.clicked) this.alertService.showMessage("Pomyślnie dodano do koszyka", MessageType.Info);
      course.clicked = true;
      course.chapters.forEach((element: ChapterItem) => {
        element.clicked = true;
      });
    }
  }

  public getThumbnailUrl(chapterId: number, videoFileName: string) {
    let url = `/api/Course/thumbnail/${chapterId}/${videoFileName}`;
    return url;
  }
}
