import { Component, OnInit } from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import { IUserDto, UserClient, UserDto } from "api/api";
import { AlertService, MessageType } from "../../services/alert.service";

@Component({
  selector: "app-registeration-page",
  templateUrl: "./registeration-page.component.html",
  styleUrls: ["./registeration-page.component.scss"],
})
export class RegisterationPageComponent implements OnInit {
  policyVisible = false;
  policyChecked = false;
  private redirectToCart = "";
  showPolicy() {
    this.policyVisible = true;
  }
  userDto: IUserDto = {
    firstName: "",
    lastName: "",
    username: "",
    phoneNumber: "",
  };
  password = "";
  passwordRepeated = "";
  constructor(private userService: UserClient, private alertService: AlertService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.redirectToCart = params.redirectToCart;
    });
  }

  public registerUser() {
    if (!this.policyChecked) {
      this.alertService.showMessage("Brak akceptacji regulaminu platformy", MessageType.Error);

      return;
    }
    if (this.password !== this.passwordRepeated) {
      this.alertService.showMessage("Podane hasła nie zgadzają się", MessageType.Error);
      return;
    }
    this.userService.register(this.password, new UserDto(this.userDto)).subscribe(
      (next) => {
        this.alertService.showMessage("Na twój adres e-mail został wysłany link do aktywacji konta", MessageType.Info);
        if(this.redirectToCart == 'true') {
          this.router.navigate(["/web/login"],{ queryParams: {redirectToCart: true} });
        }
        else
          this.router.navigateByUrl("/web/login");
      },
      (error) => {
        this.alertService.showMessage(error.message, MessageType.Error);
      }
    );
  }
}
