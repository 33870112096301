import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { PaginatorComponent } from "../paginator/paginator.component";

@Component({
  selector: "app-gallery-page",
  templateUrl: "./gallery-page.component.html",
  styleUrls: ["./gallery-page.component.scss"],
})
export class GalleryPageComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @ViewChild("paginator") public paginator: PaginatorComponent;

  galleryList = {
    achievements: {
      name: "achievements",
      pictures: [
        "Certyfikat Zasługi dla Jerzego Szymańskiego od IFBB - podpisał Ben Weider",
        "Diamentowa Odznaka PZKFiTS dla Jerzego Szymańskiego 2015",
        "Drużynowe 1",
        "Drużynowe 2",
        "Drużynowe 3",
        "I miejsce w Plebiscycie na Najlepszego Trenera Miasta Zabrze 2016 dla Prezesa",
        "I miejsce w rankingu najlepszych Klubów w Polsce 2010-2014",
        "I miejsce w rankingu najlepszych Klubów w Polsce 2014-2018",
        "III miejsce w Plebiscycie Sportowa Twarz Zabrza 2014",
        "III miejsce w rankingu najlepszych Klubów w Polsce 2006-2010",
        "Medal dla Pani Prezes od IPF za organizację Mistrzostw Świata w Trójboju Siłowym 2016 A",
        "Medal dla Pani Prezes od IPF za organizację Mistrzostw Świata w Trójboju Siłowym 2016 B",
        "Medal dla Prezesa jak Zasłużonego Działacza Kulturystyki od Pawła Filleborna 1",
        "Medal dla Prezesa jak Zasłużonego Działacza Kulturystyki od Pawła Filleborna 2",
        "Mistrz Metod Treningowych nadany Jerzemu Szymańskiemu przez IFBB 2016",
        "Międzynarodowe uprawnienia trenerskie Prezesa",
        "Międzynarodowe uprawnienie trenerskie od Pani Prezes",
        "Srebrny Medal dla Jerzego Szymańskiego do IFBB 1",
        "Srebrny Medal dla Jerzego Szymańskiego od IFBB 2",
        "Srebrny Medal IFBB dla Jerzego Szymańskiego",
        "Statuetki",
        "Uprawnienia na trenera personalnego PZKFiTS od Pani Prezes",
        "Za organizację Mistrzostw Polski Juniorów i Weteranów 2015",
        "Za organizację Mistrzostw Polski w KiF 2011",
        "Za organizację Mistrzostw Polski w KiF 2012",
        "Za organizację Mistrzostw Polski w KiF 2014",
        "Za organizację Mistrzostw Polski w KiF 2015",
        "Za organizację Mistrzostw Polski w KiF 2017",
        "Za organizację Mistrzostw Świata Juniorów w Trójboju Siłowym 2012",
        "Za organizację Pucharu Polski i Mistrzostw Polski JiW 2009",
        "Za organizację Pucharu Polski i Mistrzostw Polski JiW 2010",
        "Zwycięstwa drużynowe i podziękowania",
        "Zwycięstwa drużynowe za sezon jesienny 2020r.",
        "Złoty Medal dla Jerzego Szymańskiego od IFBB 1",
        "Złoty Medal dla Jerzego Szymańskiego od IFBB 2",
        "Złoty Medal dla Jerzego Szymańskiego od PZKFiTS",
      ],
    },
    backstage: {
      name: "backstage",
      pictures: [
        "Adam Piwko i Maciej Brysk",
        "Agnieszka Sobczyk i Andrzej Wilczek",
        "Agnieszka Sobczyk i Stasiu Kokot",
        "Agnieszka Sobczyk Z Panią Prezes",
        "Agnieszka Sobczyk",
        "Ania Simsak Godula z mężem",
        "Arkadiusz Bortel",
        "Daria Kocjan, Bartosz Bobak i Dajana Ilnicka",
        "Dawid Buchczyk - Fitness Plażowe",
        "Dziewczyny Olimp Zabrze",
        "Dziewczyny rządzą!",
        "Eliza Piwko",
        "FB_IMG_1606216474818",
        "Filmowy backstage",
        "GIRLS POWER",
        "Gosia Tomczyk, Beata Gąsior, Agnieszka Zub-Skierczyńska, Maja Gruszczyńska-Lasek",
        "IMG-20190405-WA0002",
        "IMG-20190904-WA0005",
        "IMG-20200204-WA0023",
        "IMG-20200204-WA0045",
        "Iza Dybała i Agnieszka Sobczyk",
        "Kamil Koźlik, Sylwia Sobota, Roland Kowalczyk i Agnieszka Sokół Witkowska",
        "Karol Małecki, Prezes, Marcin Fornal i Patryk Jaśkiewicz",
        "Kasia Górawska",
        "Katarzyna Przydatek",
        "Klaudia Szczęsna-Rzepecka",
        "Krzysiu złapany na swoim treningu",
        "Krzysztof Kasprzyczak",
        "Kuba Doleciński, Iza Targosz, Michał Żądło, Benjamin Szczygieł",
        "Kuba i Darek Dolecińscy, Kasia Ludowicz i Cyprian Spychalski",
        "Kuba i Darek Dolecińscy, Szymon Łada z synem, Alicja Matwij i Pani Prezes z mężęm",
        "Kuba i Darek Dolecińscy",
        "Maciuś z rodziną",
        "Marcin Maciuś z Prezesem",
        "Mariusz Gurynowicz i Fabian Biel",
        "Mateusz Kochański",
        "Michał Krzysztofik, Marcin Kosel, Łukasz Żak",
        "Monika Olbrych z Panią Prezes",
        "Monika Olbrych",
        "Natalia Włoszczyk  - Fitness Sylwetkowe",
        "Natalia Włoszczyk i Stasiu Kokot",
        "Pani Prezes, Alicja Pyszka-Bazan, Monika Biedka, Paula Mocior",
        "Patryk Jaśkiewicz i Marcin Fornal z Prezesem",
        "Paul Poloczek z Prezesem",
        "Paul Poloczek",
        "Paweł Koch",
        "Piotr Bulik, Marcin Gwosdek, Prezes, Darek Flis, Grzegorz Syryłło i Piotr Janus",
        "Pozowanie grupowe",
        "Romanek 2",
        "Screenshot_20180930-140839",
        "Sokół 2",
        "Solidna ekipa",
        "Sylwia Ogórek",
        "Sylwia Sobota",
        "Szkolenie",
        "Szymon Łada, Justyna Zięcina, Aleksandra Maciejczyk",
        "Tomasz Nobis",
        "Warsztaty szkoleniowe",
      ],
    },
    wins: {
      name: "wins",
      pictures: [
        "Adrian Krawczyk - złoto Mistrzostw Śląska",
        "Adrian Krawczyk i Mateusz Zagórowski z Prezesem",
        "Adrian Krawczyk z Prezesem",
        "Agnieszka Sobczyk, Monika Olbrych i Beata Pepera",
        "Agnieszka Sokół Witkowska - srebro i Aneta Heluszka - złoto",
        "Agnieszka Sokół-Witkowska - srebro z Prezesem",
        "Aleksander Włodarczyk - srebro Grodzisk",
        "Aleksander Włodarczyk z Prezesem",
        "Aleksandra Krzyworzeka - złoto Mistrzostw Polski",
        "Alicja Pyszka Bazan - złoto Mistrzostw Polski",
        "Alicja Pyszka-Bazan - srebro Grodzisk z Prezesem",
        "Alicja Pyszka-Bazan - srebro",
        "Alicja Pyszka-Bazan - złoto Mistrzostw Polski",
        "Alicja Pyszka-Bazan i Patrycja Kołacz-Bucka - Fit Model",
        "Aneta Heluszka - 10 lat",
        "Aneta Heluszka - brąz Mistrzostw Świata weteranów",
        "Aneta Heluszka brązową medalistką Mistrzostw Świata",
        "Ariel Bąk - dwa razy złoto",
        "Ariel Bąk - złoto z Prezesem",
        "Artur Kozieł - złoto Mistrzotw Polski",
        "Artur Kozieł, Wojciech Kampa i Maciej Kiełtyka z Prezesem",
        "Bartosz Zimny - Mistrzostwa Europy Juniorów - złoto",
        "Bartosz Zimny - srebro i Daniel Poniedziałek - złoto Mistrzostw Europy seniorów",
        "Bartosz Zimny - złoto",
        "Benjamin Szczygieł - złoto Mistrzostw Polski JiW",
        "Cała ekipa Olimpu Zabrze",
        "Damian Kosiec - srebro Arnold Classic",
        "Damian Kosiec i Paweł Dyczek",
        "Damian Kosiec po sezonie z Prezesem",
        "Damian Kosiec z trofeami po sezonie startowym",
        "Daniel Poniedziałek - złoto Arnold Classic",
        "Daniel Poniedziałek - złoto Mistrzostw Świata",
        "Daniel Poniedziałek z Panią Prezes",
        "Dawid Gwizdol i Mariusz Bałaziński",
        "Dawid Gwizdol i Szymon Karelus",
        "Dorota Szweda - brąz",
        "Dorota Szweda - złoto Diamond Cup",
        "Dorota Szweda - złoto",
        "Ekipa zwycięskich chłopaków z Prezesem - Grodzisk",
        "FB_IMG_1599086265257",
        "Ireneusz Pochwała - złoto Pucharu Świata Fit Model",
        "Ireneusz Pochwała z Prezesem",
        "Joanna Tańska - srebro Mistrzostw Świata Juniorów",
        "Joanna Tańska - złoto Mistrzostw Polski Juniorów",
        "Joanna Tańska - złoty medal Mistrzostw Świata seniorek",
        "Joanna Tańska Mistrzynią Europy 2020",
        "Joanna Tańska Mistrzynią Świata seniorek",
        "Kamil Banaś po sezonie 2020",
        "Kamil Koźlik - brąz Mistrzostw Świata",
        "Kamil Koźlik - podwójne złoto Grodzisk 2020",
        "Kamil Koźlik - srebro Mistrzostw Europy",
        "Kamil Koźlik - złoto Mistrzostw Polski",
        "Kamil Romanek - srebro Mistrzostw Polski",
        "Katarzyna Skrzypiec - złoto Mistrzostw Polski",
        "Kolejne zwycięstwo drużynowe Mistrzostw Polski",
        "Kolejny zwycięski puchar drużynowy",
        "Krzysztof Masztalerz - złoto Grodzisk z Prezesem",
        "Krzysztof Masztalerz - złoto Mistrzostw Śląska",
        "Krzysztof Masztalerz - złoto",
        "Kuba Doleciński - złoto Mistrzostw Polski Juniorów Młodszych",
        "Kuba i Darek Dolecińscy z Prezesem",
        "Maciej Kiełtyka - złoto Arnold Classic",
        "Maciej Kiełtyka - złoto i karta pro",
        "Maciej Kiełtyka i Daniel Poniedziałek z Radkiem Słodkiewiczem",
        "Madzia Stawisiński w Olimpie ze swoimi zdobyczami",
        "Magda Stawisiński - brąz Mistrzostw Świata",
        "Magda Stawisiński - podsumowanie sezonu startowego",
        "Magda Stawisiński - srebro Mistrzostw Europy",
        "Magda Stawisiński - złoto Arnold Classic",
        "Magda Stawisiński - złoto Mistrzostw Polski",
        "Magda Stawisiński ze zwycięskim pucharem drużynowym",
        "Marcin Dziadczyk - złoto Grodzisk 2020",
        "Marcin Dziadczyk - złoto Mistrzostw Polski",
        "Marcin Farnal - złoto",
        "Marcin Fornal - złoto Diamond Cup z Prezesem",
        "Marcin Fornal i Wojciech Pieczaba",
        "Marcin Fornal zwycięzcą Diamond Cup",
        "Mariusz Bałaziński  Mistrzem Świata",
        "Mariusz Bałaziński - kolejne złoto",
        "Mariusz Bałaziński - złoto Mistrzostw Polski",
        "Mariusz Bałaziński - złoto Mistrzostw Świata",
        "Marta Banasiak i Kamil Szymocha - zwycięska para",
        "Masztalerz + Zagórowski",
        "Mateusz Kotwis - srebro Mistrzostw Polski 2020 i Tomasz Marzec",
        "Mateusz Zagórowski - złoto Diamond Cup z Pawełem Fillebornem",
        "Mateusz Zagórowski z Prezesem",
        "Małgorzata Piwko  z Prezesem",
        "Małgorzata Piwko - złoto Mistrzostw Polski",
        "Michał Grygowicz - złoto FIWE",
        "Michał Żądło",
        "Michał żądło - brąz",
        "Michał żądło z Prezesem",
        "Patrycja Kołacz Bucka - złoto Mistrzostw Śląska",
        "Patryk Jaśkiewicz - złoto Mistrzostw Europy",
        "Patryk Jaśkiewicz z Prezesem",
        "Paula Mocior - brąz Mistrzostw Świata",
        "Paula Mocior - złoto Diamond Cup z Prezesem",
        "Paula Mocior - złoto Diamond Cup",
        "Paula Mocior - złoto FIWE",
        "Paula Mocior i Piotr Borecki - złota para",
        "Piotr Borecki - srebro",
        "Piotr Nowotarski - kolejne złoto",
        "Piotr Nowotarski - złoto Mistrzostw Polski Juniorów",
        "Piotr Nowotarski - złoto",
        "Rafał Domin - złoto",
        "Rafał Domin",
        "Rafał Gumienny z Prezesem",
        "Roksana Kieruzel - brąz Grodzisk z Prezesem",
        "Stasiu Kokot, Szymon Karelu i Ariel Bąk z Prezesem",
        "Sylwia Sobota - złoto Mistrzostw Polski",
        "Sylwia Taczała - srebro Mistrzostw Świata",
        "Sylwia Taczała V-ce Mistrzynią Świata",
        "Szymon Karelus - złoto Mistrzostw Śląska z Prezesem",
        "Szymon Karelus z trofeami po sezonie startowym",
        "Szymon Łada - podwójne złoto Diamond Cup",
        "Szymon Łada - złoto Diamond Cup",
        "Szymon Łada - złoto Mistrzostw Świata",
        "Szymon Łada z Prezesem - złoto Mistrzostw Świata Pro w kult.",
        "Tomasz Marzec - kolejne złoto",
        "Tomasz Marzec - złoto Mistrzostw Świata Juniorów",
        "Tomasz Marzec - złoto",
        "Tomasz Pabiniak - złoto Mistrzostw Europy",
        "Tomasz Sadkowski - srebro Mistrzostw Świata",
        "Tomasz Sadkowski z Prezesem",
        "Tomasz Sadkowski",
        "Wanessa Zych - złoto Grodzisk 2018 z Prezesem",
        "Zwycięska ekipa z dumną Panią Prezes, która zgarneła drużynówkę",
        "Zwycięstwa drużynowe - sezon jesienny 2020r.",
        "Zwycięstwo drużynowe - Białystok 2016",
        "Zwycięstwo drużynowe - Grodzisk 2018",
        "Zwycięstwo drużynowe Mistrzostw Polski 2017",
        "Zwycięstwo drużynowe Mistrzostw Polski 2018",
        "Zwycięstwo drużynowe Mistrzostw Polski",
        "Zwycięstwo drużynowe Mistrzostw Śląska",
        "Zwycięstwo drużynowe Olimpu",
        "Zwycięstwo drużynowe Pucharu Polski 2017",
        "Łukasz Opolski - złoto 2",
        "Łukasz Opolski - złoto Mistrzostw Polski",
        "Łukasz Opolski - złoto",
      ],
    },
    competitors: {
      name: "competitors",
      pictures: [
        "Alicja Pyszka-Bazan - Fit Model w stroju",
        "Alicja Pyszka-Bazan - Fit Model w sukni wieczorowej",
        "Bartosz Bobak",
        "Cyprian Spychalski z Prezesem",
        "Darek Flis i Alicja Matwij z Prezesem",
        "Diamond Cup Zabrze z Panią Prezydent Zabrza",
        "FIWE z Kasią Ludowicz i Cyprianem Spychalskim",
        "Grzegorz Płaczkowski",
        "Iga Łęska",
        "Jarosław Sypniewski",
        "Kamil Romanek",
        "Krzysztof Masztalerz i Sebastian Szmyt",
        "Krzysztof Masztalerz",
        "Krzysztof Masztalesz - układ dowolny",
        "Maciej Kiełtyka",
        "Maciej Żochowski",
        "Mateusz Kochański",
        "Michał Grygowicz backstage",
        "Michał Grygowicz",
        "Najładniejsza część backstagu na zawodach",
        "Pan i Pani Prezes złapani w panelu sędziowskim",
        "Panel Sędziowski - FIWE",
        "Panel sędziowski - Grodzisk",
        "Panel Sędziowski Diamond Cup Katowice 2017",
        "Panel Sędziowski Diamond Cup Zabrze 2018",
        "Panel Sędziowski FIWE Diamond Cup Warsaw 2018",
        "Panel Sędziowski Mistrzostw Polski 2017",
        "Panel Sędziowski Mistrzostw w Grodzisku 2018",
        "Panel Sędziowski Mistrzostw Śląska 2018",
        "Panel Sędziowski Pucharu Polski 2017",
        "Panel sędziowski w natarciu",
        "Pani Prezes w roli Spikera Diamond Cup Zabrze",
        "Patrycja Kołacz-Bucka i Alicja Pyszka-Bazan",
        "Patryk Jaśkiewicz",
        "Paul Poloczek - Bicepsy",
        "Paul Poloczek - klatka piersiowa bokiem",
        "Paul Poloczek - Mięśnie brzucha i nóg",
        "Paul Poloczek",
        "Paula Mocior",
        "Podziękowania - Mistrzostwa Polski Kielce",
        "Podziękowanie do Prezesa za organizację Mistrzostw Polski",
        "Prezes i Peszat",
        "Prezes sumując propozycję porównań",
        "Prezes w czujnym skupieniu",
        "Prezes w roli Sędziego Głównego zawodów",
        "Przemysław Owczarek",
        "Stanisław Peszat z Prezesem",
        "Sylwia Ogórek 1",
        "Sylwia Sobota z Prezesem",
        "Szymon Łada z Arnoldem Schwarzeneggerem",
        "Tomasz Nobis",
        "Tomasz Pabiniak i jego nogi przed zawodami",
      ],
    },
  };

  gallery = this.galleryList.achievements;

  paginatedItems = undefined;

  constructor(private cdr: ChangeDetectorRef) {}
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.paginatedItems = this.gallery.pictures.slice(
      this.paginator.activePage * this.paginator.itemsPerPage,
      this.paginator.activePage * this.paginator.itemsPerPage + this.paginator.itemsPerPage
    );
    this.cdr.detectChanges();
  }

  paginatorUpdated() {
    this.paginatedItems = this.gallery.pictures.slice(
      this.paginator.activePage * this.paginator.itemsPerPage,
      this.paginator.activePage * this.paginator.itemsPerPage + this.paginator.itemsPerPage
    );
    this.cdr.detectChanges();
  }
}
