import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PaymentsClient } from "api/api";
import { Subscription } from "rxjs";
import { AlertService, MessageType } from "../../services/alert.service";
import { CartItem, CartService } from "../../services/cart.service";

@Component({
  selector: "app-cart-page",
  templateUrl: "./cart-page.component.html",
  styleUrls: ["./cart-page.component.scss"],
})
export class CartPageComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private alertService: AlertService, private cartService: CartService, private paymentsClient: PaymentsClient) {}

  cart: CartItem[] = [];
  sub: Subscription;

  ngOnInit(): void {
    this.sub = this.cartService.getShoppingCart().subscribe((result) => (this.cart = result));
  }

  removeFromCart(product) {
    this.cartService.removeFromCart(product);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  public cartPricesSum(): number {
    let sum = 0;
    for (const product of this.cart) {
      sum += product.chapter?.price ?? product.course.price;
    }
    return sum;
  }

  public processPayment() {
    //this.router.navigateByUrl("/web/home");

    this.paymentsClient
      .registerOrder(
        this.cart.filter((c) => c.chapter == undefined).map((c) => c.course.id),
        this.cart.filter((c) => c.chapter !== undefined).map((c) => c.chapter.id)
      )
      .subscribe(
        (result: any) => {
          document.location.href = result.url;
        },
        (error) => {
          this.alertService.showMessage("Zaloguj się do swojego konta aby przejść do płatności", MessageType.Error);
          this.router.navigate(["/web/login"],
            { queryParams: { redirectToCart: true } });
        }
      );
  }
}
