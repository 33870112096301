import { Component, OnInit } from "@angular/core";
import { ArticlesClient } from "api/api";

@Component({
  selector: "app-blog-page",
  templateUrl: "./blog-page.component.html",
  styleUrls: ["./blog-page.component.scss"],
})
export class BlogPageComponent implements OnInit {
  articles = [];

  constructor(public articlesClient: ArticlesClient) {}

  ngOnInit(): void {
    this.articlesClient.getArticles().subscribe((result) => (this.articles = result));
  }
}
