import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Course, CourseClient, CourseDto } from "api/api";
import { combineLatest, Observable, Subject } from "rxjs";
import { CartService } from "../../services/cart.service";
import { PaginatorComponent } from "../paginator/paginator.component";

@UntilDestroy()
@Component({
  selector: "app-products-page",
  templateUrl: "./products-page.component.html",
  styleUrls: ["./products-page.component.scss"],
})
export class ProductsPageComponent implements OnInit, AfterViewInit {
  @ViewChild("paginator") public paginator: PaginatorComponent;
  public filter1 = { shown: false };
  public filter2 = { shown: false };
  public filter3 = { shown: false };
  @ViewChild("filterButton1") public filterButton1: ElementRef<HTMLButtonElement>;
  @ViewChild("filterButton2") public filterButton2: ElementRef<HTMLButtonElement>;
  @ViewChild("filterButton3") public filterButton3: ElementRef<HTMLButtonElement>;

  activeFilters = [];
  filterChanged = new Subject<string[]>();
  paginationChanged = new Subject();
  constructor(private courseClient: CourseClient, private cdr: ChangeDetectorRef) {}

  products: CourseDto[] = [];
  paginatedItems = [];

  selectedCourse?: CourseDto;

  filteredCount = 0;

  products$ = new Observable((observer) => {
    combineLatest([this.filterChanged, this.paginationChanged]).subscribe(([activeFilters, pagination]) => {
      console.log(this.products);
      let products = this.products.filter((p) => {
        if (activeFilters.length == 0) {
          return true;
        } else {
          let alltrue = true;
          for (var filter of activeFilters.map((af) => af.toLocaleLowerCase())) {
            if (!p.tags.map((af) => af.toLocaleLowerCase()).includes(filter.toLocaleLowerCase())) alltrue = false;
          }
          return alltrue;
        }
      });
      this.filteredCount = products.length;
      observer.next(
        products.slice(
          this.paginator.activePage * this.paginator.itemsPerPage,
          this.paginator.activePage * this.paginator.itemsPerPage + this.paginator.itemsPerPage
        )
      );
    });
  });

  ngOnInit(): void {}

  onFilterClick(element, filterValue) {
    if (!this.activeFilters.includes(filterValue)) this.activeFilters.push(filterValue);
    else this.onFilterRemove(filterValue);
    element.shown = false;
    this.filterChanged.next(this.activeFilters);
  }

  onFilterRemove(filterValue) {
    const index = this.activeFilters.indexOf(filterValue, 0);
    if (index > -1) {
      this.activeFilters.splice(index, 1);
    }
    this.filterChanged.next(this.activeFilters);
  }

  setSelectedLesson(product) {
    this.selectedCourse = product;
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  filterClick(filter) {
    this.filter1.shown = false;
    this.filter2.shown = false;
    this.filter3.shown = false;
    filter.shown = true;
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (
      event.target == document.getElementById("filterButton1") ||
      event.target == document.getElementById("filterButton2") ||
      event.target == document.getElementById("filterButton3")
    )
      return;

    this.filter1.shown = false;
    this.filter2.shown = false;
    this.filter3.shown = false;
  }

  ngAfterViewInit(): void {
    this.courseClient.getAllCourses().subscribe((result) => {
      console.log(result);
      this.products = result;
      this.filterChanged.next([]);
      this.paginationChanged.next();
      this.cdr.detectChanges();
    });

    this.filterChanged.pipe(untilDestroyed(this)).subscribe((filter) => {
      this.paginator.activePage = 0;
      this.paginationChanged.next();
    });
  }

  paginatorUpdated() {
    this.paginationChanged.next();
  }
}
