import { Component, Input, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"],
})
export class PaginatorComponent implements OnInit {
  @Input() public itemsCount: number = 0;
  @Input() public itemsPerPage: number = 8;
  public activePage: number = 0;

  @Output() paginatorUpdated = new Subject<number>();

  constructor() {}

  ngOnInit(): void {}

  pagesCount() {
    let res = Math.floor(this.itemsCount / this.itemsPerPage) + (this.itemsCount % this.itemsPerPage == 0 ? 0 : 1);
    console.log(this.itemsCount);
    return res;
  }

  setActivePage(page: number) {
    this.activePage = page;
    this.paginatorUpdated.next(page);
  }
}
