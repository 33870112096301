import { animate, group, query, stagger, style, transition, trigger } from "@angular/animations";
import { Component, Inject, Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserClient, UserDto } from "api/api";
import { ReplaySubject, Subject } from "rxjs";
export const options = [
  { route: "home", label: "Strona główna" },
  { route: "gallery", label: "Galeria" },
  { route: "products", label: "Filmy treningowe" },
  { route: "about-us", label: "O Nas" },
  { route: "about-platform", label: "O platformie" },
  { route: "contact", label: "Kontakt" },
  { route: "blog", label: "Artykuły" },
  { route: "cart", label: "Koszyk" },
  { route: "policy", label: "Regulamin" },
  { route: "privacy-policy", label: "Polityka prywatności" },
];
@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  animations: [
    trigger("fadeInOutAnimations", [
      transition(":enter", [
        group([
          query(
            ".animated-left-to-right",
            [
              style({ opacity: 0, transform: "translateX(70px) " }),
              stagger(30, [animate("300ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: 1, transform: "translateX(0) " }))]),
            ],
            { optional: true }
          ),
          query(
            ".animated-right-to-left",
            [
              style({ opacity: 0, transform: "translateX(-70px) " }),
              stagger(30, [animate("300ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: 1, transform: "translateX(0) " }))]),
            ],
            { optional: true }
          ),
          query(
            ".animated-top-to-bottom",
            [
              style({ opacity: 0, transform: "translateY(-20px) " }),
              stagger(30, [animate("300ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: 1, transform: "translateY(0) " }))]),
            ],
            { optional: true }
          ),
        ]),
      ]),

      transition(":leave", [
        group([
          query(
            ".animated-left-to-right",
            [
              style({ opacity: 1, transform: " " }),
              stagger(20, [animate("300ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: 0, transform: "translateX(0) " }))]),
            ],
            { optional: true }
          ),
          query(
            ".animated-right-to-left",
            [
              style({ opacity: 1, transform: " " }),
              stagger(20, [animate("300ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: 0, transform: "translateX(0) " }))]),
            ],
            { optional: true }
          ),
          query(
            ".animated-top-to-bottom",
            [
              style({ opacity: 1, transform: " " }),
              stagger(20, [animate("300ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: 0, transform: "translateY(0) " }))]),
            ],
            { optional: true }
          ),
        ]),
      ]),
    ]),
  ],
})
export class NavigationComponent implements OnInit {
  public navigationExpanded = false;
  public options = options;
  public currentUser;

  constructor(private router: Router, private userClient: UserClient, private userService: UserService) {}

  ngOnInit(): void {
    this.userClient.getCurrentUser().subscribe((user) => this.userService.currentUser$.next(user));
    this.userService.currentUser$.subscribe((us) => (this.currentUser = us));
  }

  public toggleExpanded() {
    this.navigationExpanded = !this.navigationExpanded;
  }

  userIconClicked() {
    //if (!this.configuration.apiKeys["Authorization"])
    //  this.router.navigateByUrl("/web/login");
    //else {
    this.router.navigateByUrl("/web/cart");
    //}
  }
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  public currentUser$: ReplaySubject<UserDto> = new ReplaySubject(undefined);
}
