import { Component, OnInit } from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import { ApiBase, AuthenticationClient, UserClient, UserCredentials } from "api/api";
import { AlertService, MessageType } from "../../services/alert.service";

import { Directive, ElementRef } from "@angular/core";
import { UserService } from "../navigation/navigation.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@Directive({
  selector: "[appPrefixFocusAndSelect]",
})
export class FocusOnShowDirective implements OnInit {
  constructor(private el: ElementRef) {
    if (!el.nativeElement["focus"]) {
      throw new Error("Element does not accept focus.");
    }
  }

  ngOnInit(): void {
    const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
    input.focus();
    input.select();
  }
}
@UntilDestroy()
@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
  login = "";
  password = "";
  redirectToCart = "";

  constructor(
    private authenticationService: AuthenticationClient,
    private alertService: AlertService,
    private router: Router,
    private userClient: UserClient,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.redirectToCart = params.redirectToCart;
        console.log(this.redirectToCart);
      });
  }

  navigateToRegistration(){
    if(this.redirectToCart == 'true') {
      this.router.navigate(["/web/register"],{ queryParams: {redirectToCart: true} });
    }
    else
      this.router.navigate(["/web/register"],{ queryParams: {redirectToCart: false} });
  }

  public authenticateUser() {
    this.authenticationService
      .authenticateInternalUser(
        new UserCredentials({
          username: this.login,
          password: this.password,
        })
      )
      .subscribe(
        (next) => {
          this.authenticationService.setAuthToken(next.token);
          this.userClient.getCurrentUser().pipe(untilDestroyed(this)).subscribe((user) => {
            this.userService.currentUser$.next(user);

            if(this.redirectToCart == "true") {
              console.log("redirecting to cart")
              this.router.navigateByUrl("/web/cart");
            }
            else {
              console.log("redirecting to home")
              this.router.navigateByUrl("/web/home");
            }
          });
        },
        (error) => {
          console.log(error);
          this.alertService.showMessage("Niepoprawne hasło lub konto nie zostało aktywowane", MessageType.Error);
        }
      );
  }
}
