import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChapterDto, CourseClient, CourseDto, Video } from "api/api";
import { AlertService, MessageType } from "src/app/web/services/alert.service";
import { CartService } from "src/app/web/services/cart.service";
import { UserService } from "../../navigation/navigation.component";
import { CourseItem, ChapterItem } from "../product-details/product-details.component";

@Component({
  selector: "app-product-details-page",
  templateUrl: "./product-details-page.component.html",
  styleUrls: ["./product-details-page.component.scss"],
})
export class ProductDetailsPageComponent implements OnInit {
  course: CourseDto;

  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() addToCart: EventEmitter<void> = new EventEmitter();

  @ViewChild("videoPlayer") videoPlayer: ElementRef<HTMLVideoElement>;

  activeChapter: ChapterDto;
  activeVideo: Video;

  currentVideoUrl = "";

  showCartButton = false;

  userHash: string;

  constructor(
    public cartService: CartService,
    private alertService: AlertService,
    private courseClient: CourseClient,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get("id");
    this.courseClient.getAllCourses().subscribe((c) => {
      this.course = c.find((course) => course.id.toString() == id);
      this.setActiveChapter(this.course.chapters[0]);
    });
    this.userService.currentUser$.subscribe((u) => {
      console.log(u);
      this.userHash = u.userHash;
    });
  }

  public closeDialog() {
    this.close.next();
  }

  setActiveVideo(video: Video) {
    this.activeVideo = video;
    this.currentVideoUrl = "/api/Course/video/" + this.activeChapter?.id + "/" + this.userHash + "/" + this.activeVideo.fileName;
    this.videoPlayer?.nativeElement?.load();
  }

  setActiveChapter(chapter: ChapterDto) {
    if (chapter == undefined) {
      this.activeVideo = this.course.chapters[0].videos[0];
      this.activeChapter = undefined;

      this.currentVideoUrl = "/api/Course/video/" + this.course.chapters[0].id + "/" + this.userHash + "/" + this.activeVideo.fileName;
    } else {
      this.activeChapter = chapter;
      this.activeVideo = chapter.videos[0];
      this.currentVideoUrl = "/api/Course/video/" + this.activeChapter?.id + "/" + this.userHash + "/" + this.activeVideo.fileName;
    }
    this.videoPlayer?.nativeElement?.load();
  }

  addToShoppingCart(course: CourseItem, chapter: ChapterItem) {
    this.cartService.addToShopingCart(course, chapter);
    this.showCartButton = true;
    if (chapter != undefined) {
      if (!chapter.clicked) this.alertService.showMessage("Pomyślnie dodano do koszyka", MessageType.Info);
      chapter.clicked = true;
    } else {
      if (!course.clicked) this.alertService.showMessage("Pomyślnie dodano do koszyka", MessageType.Info);
      course.clicked = true;
      course.chapters.forEach((element: ChapterItem) => {
        element.clicked = true;
      });
    }
  }

  public getThumbnailUrl(chapterId: number, videoFileName: string) {
    let url = `/api/Course/thumbnail/${chapterId}/${videoFileName}`;
    return url;
  }

  public scrollRight() {
    document.getElementById("playlist").scrollBy({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }

  public scrollLeft() {
    document.getElementById("playlist").scrollBy({
      top: 0,
      left: -100,
      behavior: "smooth",
    });
  }
}
