import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { AlertService, MessageType } from "src/app/web/services/alert.service";
import { ExtendedTableComponent } from "../../extended-management";

import { DriveMovieImporterClient, UserDto, UsersClient } from "api/api";
import { UserWizardComponent } from "./user-wizard/user-wizard.component";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent extends ExtendedTableComponent implements OnInit {
  displayedColumns = ["select", "name", "group", "details"];
  constructor(private alertService: AlertService, private usersClient: UsersClient, private dialog: MatDialog) {
    super();
  }

  openDialog(mode: string, user: UserDto) {
    const dialogRef = this.dialog.open(UserWizardComponent, {
      data: { mode: mode, user: user },
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  deleteSelected() {
    this.selection.selected.forEach((val, index, array) => {
      this.usersClient.delete3(val.id).subscribe(
        (next) => {
          this.alertService.showMessage("Pomyślnie usunięto użytkownika", MessageType.Info);
          this.selection.clear();
          this.reloadData();
        },
        (error) => {
          this.alertService.showMessage("Wystąpił błąd podczas usuwania użytkownika: " + error.error, MessageType.Error);
        }
      );
    });
  }

  reloadData() {
    this.loading = true;
    this.usersClient.getAll3().subscribe((result: UserDto[]) => {
      this.updateTableData(result);
    });
  }
  exportData() {
    const data = JSON.stringify(this.dataSource.data, null, 2);
    const blob: Blob = new Blob([data], { type: "text/json" });
    const fileName = "export.json";
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }
  ngOnInit() {
    this.reloadData();
  }
}
