import { Component, OnInit, Inject } from "@angular/core";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";
import { GroupDto, GroupsClient, UserDto, UsersClient } from "api/api";
import { AlertService, MessageType } from "src/app/web/services/alert.service";
@Component({
  selector: "app-user-wizard",
  templateUrl: "./user-wizard.component.html",
  styleUrls: ["./user-wizard.component.scss"],
})
export class UserWizardComponent implements OnInit {
  password: string;
  hidePassword = true;
  sendMail = false;

  userGroups: GroupDto[];
  user: UserDto;

  constructor(
    private userService: UsersClient,
    private groupService: GroupsClient,
    private alertService: AlertService,
    public dialog: MatDialogRef<UserWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.groupService.getAll2().subscribe((value) => {
      this.userGroups = value;
    });
    this.user = this.data.user === undefined ? {} : this.data.user;
  }

  valueChange($event) {
    this.sendMail = $event.checked;
    console.log(this.sendMail);
  }

  onSubmit(form): void {
    console.log(this.sendMail);
    switch (this.data.mode) {
      case "create":
        this.userService.add2(this.password === "" ? null : this.password, this.sendMail, this.user).subscribe((result: any) => {
          this.alertService.showMessage("Pomyślnie dodano użytkownika", MessageType.Info);
          this.dialog.close(true);
        });
        break;
      case "edit":
        this.userService.update3(this.data.user.id, this.password === "" ? null : this.password, this.sendMail, this.user).subscribe((result: any) => {
          this.alertService.showMessage("Pomyślnie zaktualizowano dane użytkownika", MessageType.Info);
          this.dialog.close(true);
        });
        break;
    }
  }
}
