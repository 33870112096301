import { query, style, animateChild, group, animate, transition, trigger, stagger, state } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";

@Component({
  selector: "app-web",
  templateUrl: "./web.component.html",
  styleUrls: ["./web.component.scss"],
  animations: [
    trigger("routeAnimations", [
      transition("* <=> *", [
        style({ position: "relative" }),
        query(
          ":enter, :leave",
          [
            style({
              position: "absolute",
              top: "calc(5.3125rem + 1px)",
              width: "100%",
              opacity: 0,
            }),
          ],
          { optional: true }
        ),
        query(
          ":leave",
          [
            style({
              opacity: 1,
            }),
          ],
          { optional: true }
        ),

        query(":leave", [animate("200ms ease-out", style({ opacity: 0 }))], {
          optional: true,
        }),
        query(
          ":enter",
          [
            style({
              opacity: 0,
            }),
          ],
          { optional: true }
        ),

        query(
          ":enter",
          [
            style({
              opacity: 0,
            }),
            animate("200ms ease-out", style({ opacity: 1 })),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class WebComponent implements OnInit {
  constructor(public router: Router) {
    console.log(router.url);
  }

  ngOnInit(): void {}
  prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData?.["animation"];
  }
}
