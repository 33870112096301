import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-bumper",
  templateUrl: "./bumper.component.html",
  styleUrls: ["./bumper.component.scss"],
})
export class BumperComponent implements OnInit {
  @Input() position: "top-right" | "top-left" | "bottom-left" | "bottom-right" = "top-left";

  @Input() offset = "0";

  constructor() {}

  ngOnInit(): void {}
}
