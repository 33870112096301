import { Component, HostBinding, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-title-bar",
  templateUrl: "./title-bar.component.html",
  styleUrls: ["./title-bar.component.scss"],
})
export class TitleBarComponent implements OnInit {
  @Input() background: "none" | "gold" | "black" = "none";

  // @HostBinding("style.background") get _background() {
  //   return this.background === "gold" ? "#C8A624" : this.background;
  // }

  constructor() {}

  ngOnInit(): void {}
}
