import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { routing } from "./admin-panel.module";

@Component({
  selector: "app-admin-panel",
  templateUrl: "./admin-panel.component.html",
  styleUrls: ["./admin-panel.component.scss"],
})
export class AdminPanelComponent implements OnInit {
  title = "WebCMS";
  userName: string;
  clientName = "Centrum Nowoczesności Młyn Wiedzy";
  menuRoutes = routing;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.userName = "Super User";
  }
}
