import { Component, HostBinding, Input, OnInit } from "@angular/core";

@Component({
  selector: "[app-button]",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() type: "primary" | "secondary" = "primary";

  @HostBinding("class.primary") get isPrimary() {
    return this.type === "primary";
  }
  @HostBinding("class.secondary") get isSecondary() {
    return this.type === "secondary";
  }

  constructor() {}

  ngOnInit(): void {}
}
