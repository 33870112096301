import { Injectable } from "@angular/core";
import { ChapterDto, CourseDto, DriveMovieImporterClient } from "api/api";
import { Observable, of, ReplaySubject, Subject } from "rxjs";
import { map } from "rxjs/operators";

export const sexFilterOptions = ["Mężczyzna", "Kobieta"];

export class CartItem {
  course?: CourseDto;
  chapter?: ChapterDto;
}

@Injectable({
  providedIn: "root",
})
export class CartService {
  constructor(private service: DriveMovieImporterClient) {}

  cart: CartItem[] = [];
  cart$ = new ReplaySubject<CartItem[]>();

  getShoppingCart(): Observable<CartItem[]> {
    return this.cart$;
  }

  public addToShopingCart(course?: CourseDto, chapter?: ChapterDto) {
    if (this.cart.find((c) => c.chapter == chapter && c.course == course) == undefined) this.cart.push({ course, chapter });

    this.cart
      .filter((c) => c.chapter == undefined)
      .forEach((c) => {
        c.course.chapters.forEach((element) => {
          let el = this.cart.find((f) => f.course.id == c.course.id && f.chapter !== undefined && f.chapter.id == element.id);
          if (el) this.cart.splice(this.cart.indexOf(el), 1);
        });
      });
    this.cart$.next(this.cart);
  }

  public removeFromCart(prod) {
    this.cart.splice(prod, 1);
    this.cart$.next(this.cart);
  }
}
