import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GroupDto, GroupsClient } from "api/api";
import { AlertService, MessageType } from "src/app/web/services/alert.service";
import { ExtendedTableComponent } from "../../extended-management";
import { GroupWizardComponent } from "./group-wizard/group-wizard.component";

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.scss"],
})
export class GroupsComponent extends ExtendedTableComponent implements OnInit {
  displayedColumns = ["select", "name", "operations"];
  constructor(private alertService: AlertService, private groupService: GroupsClient, private dialog: MatDialog) {
    super();
  }

  openDialog(mode: string, group: GroupDto = new GroupDto()) {
    const dialogRef = this.dialog.open(GroupWizardComponent, {
      data: { mode: mode, group: group },
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  deleteSelected() {
    this.selection.selected.forEach((element) => {
      this.groupService.delete2(element.id).subscribe(
        (next) => {
          this.alertService.showMessage("Pomyślnie usunięto grupę", MessageType.Info);

          this.selection.clear();
          this.reloadData();
        },
        (error) => this.alertService.showMessage("Błąd podczas usuwania grupy", MessageType.Error)
      );
    });
  }

  exportData() {
    const data = JSON.stringify(this.dataSource.data, null, 2);
    const blob: Blob = new Blob([data], { type: "text/json" });
    const fileName = "export.json";
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  reloadData() {
    this.loading = true;
    this.groupService.getAll2().subscribe((result) => {
      this.updateTableData(result.map((r) => new GroupDto(r)));
    });
  }

  ngOnInit() {
    this.reloadData();
  }
}
