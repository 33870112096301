import { Component, OnInit } from "@angular/core";
import { ExtendedTableComponent } from "../../extended-management";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent extends ExtendedTableComponent implements OnInit {
  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }
  // constructor(
  //   private alertService: AlertService,
  //   private productsService: ProductsService,
  //   private dialog: MatDialog
  // ) {
  //   super();
  // }

  // displayedColumns = ["status", "code", "productPrice", "dateTime", "details"];

  // product: ProductDto = {};

  // createDialog(model) {}
  // editDialog(model) {}

  // deleteSelected() {}

  // showVoucher(val) {
  //   this.loading = true;
  //   this.productsService.getAllAsUser(val.trim()).subscribe(
  //     (result: ProductDto[]) => {
  //       this.loading = false;
  //       console.log(result);
  //       this.updateTableData(result);
  //     },
  //     (error) => {
  //       console.log(error.error);
  //     }
  //   );
  // }

  // utilizeVoucher(voucher) {
  //   this.productsService
  //     .utilizeVoucher(voucher.orderId, voucher.code)
  //     .subscribe(
  //       (result) => {
  //         this.alertService.showMessage(
  //           "Pomyślnie aktywowano voucher",
  //           MessageType.Info
  //         );
  //         this.showVoucher(voucher.code);
  //       },
  //       (error) => {
  //         this.alertService.showMessage(
  //           "Błąd podczas przetwarzania vouchera: " + error.error,
  //           MessageType.Error
  //         );
  //         console.error(error);
  //       }
  //     );
  // }

  // cancelVoucherUtilization(voucher) {
  //   this.productsService
  //     .cancelVoucherUtilization(voucher.orderId, voucher.code)
  //     .subscribe(
  //       (result) => {
  //         this.alertService.showMessage(
  //           "Pomyślnie anulowano zużycie vouchera",
  //           MessageType.Info
  //         );
  //         this.reloadData();
  //       },
  //       (error) => {
  //         this.alertService.showMessage(
  //           "Błąd podczas przetwarzania vouchera: " + error.error,
  //           MessageType.Error
  //         );
  //         console.error(error);
  //       }
  //     );
  // }

  // reloadData() {
  //   this.loading = false;
  //   //this.loading = true;
  //   //this.productsService.getAllAsUser().subscribe(
  //   //    (result: ProductDto[]) => {
  //   //        console.log(result);

  //   //        this.updateTableData(result);
  //   //    }, error => {
  //   //      console.log(error.error);
  //   //    });
  // }

  // ngOnInit() {
  //   this.reloadData();
  // }
}
