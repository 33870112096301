import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-payment-dialog",
  templateUrl: "./payment-dialog.component.html",
  styleUrls: ["./payment-dialog.component.scss"],
})
export class PaymentDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
