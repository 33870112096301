import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationClient } from "api/api";
import { AlertService, MessageType } from "src/app/web/services/alert.service";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit {
  public hidePassword = true;

  constructor(private alertService: AlertService, private authenticationClient: AuthenticationClient, private router: Router) {}

  ngOnInit() {}

  public authenticate(form) {
    this.authenticationClient.authenticateInternalUser(form.value).subscribe(
      (token) => {
        // this.alertService.showMessage("Pomyślnie zalogowano", MessageType.Info);
        //localStorage.setItem("bearer", token);
        this.router.navigateByUrl("/");
      },
      (error) => {
        this.alertService.showMessage(error.error, MessageType.Error);
      }
    );
  }

  public passwordChange() {
    this.router.navigateByUrl("/password-change");
  }
}
