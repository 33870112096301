import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./pages/login/login.component";
import { LoginFormComponent } from "./pages/login/login-form/login-form.component";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { AdminPanelComponent } from "./admin-panel.component";
import { UsersComponent } from "./pages/users/users.component";
import { GroupsComponent } from "./pages/groups/groups.component";
import { AuthGuard } from "./auth.guard";
import { ProductsComponent } from "./pages/products/products.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./token-interceptor.service";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
export const routing = [
  {
    path: "",
    component: AdminPanelComponent,
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      // {
      //   path: "home",
      //   component: HomeComponent,
      //   pathMatch: "full",
      //   canActivate: [AuthGuard],
      // },
      { path: "login", component: LoginComponent },
      // { path: "password-change", component: PasswordChangeComponent },
      // { path: "dashboard", component: HomeComponent, canActivate: [AuthGuard] },
      {
        path: "user-management",
        data: {
          title: "Administracja",
        },
        children: [
          {
            path: "users",
            component: UsersComponent,
            data: {
              title: "Użytkownicy",
              icon: "group",
            },
            canActivate: [AuthGuard],
          },
          {
            path: "groups",
            component: GroupsComponent,
            data: {
              title: "Grupy i Uprawnienia",
              icon: "group_work",
            },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "site-management",
        data: {
          title: "Zarządzanie stroną",
        },
        children: [
          {
            path: "products",
            component: ProductsComponent,
            data: {
              title: "Produkty",
              icon: "receipt",
            },
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [LoginComponent, LoginFormComponent, AdminPanelComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatTableModule,
    RouterModule.forChild(routing),
  ],
  providers: [HttpClientModule, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }],
})
export class AdminPanelModule {}
