import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { CourseDto } from "api/api";
import { CartService } from "src/app/web/services/cart.service";

@Component({
  selector: "app-video-tile",
  templateUrl: "./video-tile.component.html",
  styleUrls: ["./video-tile.component.scss"],
})
export class VideoTileComponent implements OnInit {
  @Input() product: CourseDto;
  //@Output() click: EventEmitter<void> = new EventEmitter();

  //@HostListener("click") onClick() {
  //  this.click.next();
  //}

  constructor(private cartService: CartService, private router: Router) {}

  ngOnInit(): void {}

  public getThumbnailUrl(chapterId: number, videoFileName: string) {
    let url = `/api/Course/thumbnail/${chapterId}/${videoFileName}`;
    return url;
  }
}
